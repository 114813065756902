@use '../utils' as *;
/*-----------------------------------------------------------------------------------

    Theme Name: Template Name – HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Template Name  – HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
	01. about CSS START
	02. blog CSS START
	03. brand CSS START
	04. brand CSS START
**********************************************/

/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/

@import url($font-url);

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
	font-family: var(--tp-ff-body);
	font-size: 14px;
	font-weight: 400;
	color: var(--tp-text-body);
	line-height: 26px;
	overflow-x: hidden
}

a{
	text-decoration: none;
	transition: .3s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--tp-ff-heading);
	color: var(--tp-header-1);
	margin-top: 0px;
	font-weight: var(--tp-fw-sbold);
	line-height: 1.2;
	@include transition(.3s);
}

h1 {
	font-size: 40px;
}
  
h2 {
font-size: 32px;
}

h3 {
font-size: 28px;
}

h4 {
font-size: 24px;
}

h5 {
font-size: 20px;
}

h6 {
font-size: 16px;
}

ul {
	margin: 0px;
	padding: 0px;
}

p {
	font-family: var(--tp-ff-p);
	font-size: 18px;
	font-weight: 400;
	color: var(--tp-text-body);
	margin-bottom: 15px;
	line-height: 30px;
}


.z-index{
	position: relative;
	z-index: 3;
}

a,
.btn,
button,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}
li{
	list-style: none;
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	color: inherit;
	text-decoration: none;
}

a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}

button:hover{
	cursor: pointer;
}

button:focus{
    outline: 0; 
}

.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

input{
	outline: none;
}

input[type="color"] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
	border: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	padding: 0;
	border-radius: 50%;
}

*::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}


*::-moz-placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}
*::placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img{
	& img{
		width: 100%;
	}
}

.m-img{
	& img{
		max-width: 100%;
	}
}

.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left{
	float: left;
}
.f-right{
	float: right;
}

.z-index-1{
	position: relative;
	z-index: 2;
}

.z-index{
	position: relative;
	z-index: 1;
}

.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}

.include-bg{
	@include background();
}

// custom gutter 
.gx-45{
    --bs-gutter-x: 45px;
	@media #{$xs}{
		--bs-gutter-x: 15px;
    }
}
.gx-12{
    --bs-gutter-x: 12px;
    --bs-gutter-y: 12px;
	@media #{$md}{
		--bs-gutter-x: 30px;
    }
}
.gx-40{
    --bs-gutter-x: 40px;
	@media #{$xs}{
		--bs-gutter-x: 0px;
    }
}
.gx-120{
    --bs-gutter-x: 120px;
	@media #{$lg}{
		--bs-gutter-x: 50px;
    }
	@media #{$md}{
		--bs-gutter-x: 40px;
    }
	@media #{$xs}{
		--bs-gutter-x: 0px;
    }
}
.gx-1{
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
}

.tp-gx-20{
	--bs-gutter-x: 20px;
}
.tp-gx-15{
	--bs-gutter-x: 15px;
}

.gx-6 {
	--bs-gutter-x: 60px;
	@media #{$xl}{
		--bs-gutter-x: 40px;
    }
	@media #{$xs}{
		--bs-gutter-x: 15px;
    }
}
.gx-7 {
	--bs-gutter-x: 70px;
	@media #{$xs} {
		--bs-gutter-x: 0px;
	}
}
.gx-17{
    --bs-gutter-x: 17px;
	--bs-gutter-y: 17px;
	@media #{$md}{
		--bs-gutter-x: 30px;
		--bs-gutter-y: 30px;
    }
}
.gx-50{
    --bs-gutter-x: 50px;
	@media #{$xs}{
		--bs-gutter-x: 0px;
    }
}
.radius-20{
	border-radius: 20px;
}