@use '../utils' as *;



.body-overlay{
    &.apply{
        position: fixed;
        top: 0;
        right: 0;
        width:100%;
        height:100%;
        background-color: rgba(0,0,0,.4);
        z-index: 999;
        transition: .3s;
    }
}