/*----------------------------------------*/
/*  09. creative CSS START
/*----------------------------------------*/

@use '../utils' as *;

.tp-creative-content{
    & .tp-creative-title{
        color:var(--tp-common-white);
        font-weight: 700;
        font-size: 45px;
        line-height: 1.3;
        @media #{$lg}{
            font-size: 40px;
        }
        @media #{$md}{
            font-size: 32px;
        }
        @media #{$xs}{
            font-size: 26px;
        }
        & b{
            color: var(--tp-theme-primary);
            text-decoration: underline;
        }
    }
}
.tp-play-button{
    & button{
        height: 90px;
        width: 90px;
        border-radius: 50%;
        border: 2px solid #fff;
        display: inline-block;
        text-align: center;
        line-height: 90px;
        margin-right: 20px;
        color: var(--tp-common-white);
        background-color: transparent;
        @media #{$xs}{
         margin-bottom: 20px;
        }
        & i{
            font-size: 22px;
        }
        &:hover{
            background-color: var(--tp-theme-primary);
            color: var(--tp-common-black);
            border-color: var(--tp-theme-primary);
        }
    }
    & span{
        font-size: 18px;
        color:#fff;
        @media #{$xs}{
            display: block;
           }
    }
}
.tp-creative-item{
    background-color: var(--tp-theme-primary);
    padding: 40px 50px;
    border-radius: 20px;
    @media #{$xl}{
        padding: 40px 40px;
    }
    @media #{$lg}{
        padding: 20px 15px;
    }
    @media #{$xs}{
        padding: 40px 30px;
    }
    &:hover{
        & .tp-creative-icon{
            @include top-to-bottom-hover();
            & i{
                color: rgba(41, 41, 48, .8);
            }
        }
    }
}
.creative-box{
    @media #{$md,$xs}{
        padding-left: 0;
        padding-right: 0;
    }
}

.counter-text{
    & span{
        font-size: 45px;
        font-weight: 700;
        color: var(--tp-common-black);
        line-height: 1;
        @media #{$lg} {
            font-size: 40px;
        }
    }
    & p{
        font-size: 18px;
        color: var(--tp-common-black);
        font-weight: 500;
        @media #{$lg} {
            font-size: 15px;
        }
        @media #{$xs} {
            font-size: 16px;
        }
    }
}
.tp-creative-blue{
    background-color: var(--tp-theme-secondary);
}
.tp-creative-pink{
    background-color: #FFADAD;
}
.creative-shape-img{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    @media #{$md}{
        top: 31%;
        left: -74px;
    }
    @media #{$sm}{
        top: 29%;
        left: -100px;
    }
    @media #{$xs}{
        top: 29%;
        left: -102px;
        transform: translateY(-50%) rotate(17deg);
        transform-origin: left bottom;
    }
    
}
.creative-shape-img-2{
    position: absolute;
    top: 50%;
    right: -200px;
    animation: zoom 5s infinite;
    @media #{$xxl}{
        top: 30%;
    }
    @media #{$xl}{
        top: 41%;
        right: -267px;
    }
}
.tp-creative-icon{
    @include top-to-bottom();
    @media #{$lg} {
        margin-right: 15px;
    }
    @media #{$xs} {
        margin-right: 20px;
    }
    & i{
        transition: .4s;
        font-size: 77px;
        color: rgba(41, 41, 48, .3);
    }
}
.shape-two {
	position: absolute;
	top: -100px;
	left: 0;
}
.creative-box {
    @media #{$xl}{
        padding: 0;
    }
}
.tp-creative-icon{
    @media #{$xl}{
        margin-right: 20px;;
    }
}
.creative-z-index{
    position: relative;
    z-index: 2;
}
.circle-animation{
    &.testimonial {
        top: 50%;
        left: 50%;
        border: 1px solid #3F3F47;
        width: 900px;
        height: 900px;
        position: absolute;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        @media #{$md}{
            top: 28%;
            width: 700px;
            height: 700px;
        }
        @media #{$xs}{
            top: 32%;
            width: 330px;
            height: 330px;
        }
    
    }
}
.circle-animation {
    & span{
        &.tp-circle-1 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            -webkit-animation: circle-animation 13s linear infinite;
            -moz-animation: circle-animation 13s linear infinite;
            -o-animation: circle-animation 13s linear infinite;
            animation: circle-animation 13s linear infinite;
        }
    }
}
.circle-animation{
    & span{
        &.tp-circle-2 {
            display: block;
            position: absolute;
            content: "";
            top: calc(50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            transform: scale(-1, 1);
            -webkit-animation: circle-animation2 13s linear infinite;
            -moz-animation: circle-animation2 13s linear infinite;
            -o-animation: circle-animation2 13s linear infinite;
            animation: circle-animation2 13s linear infinite;
        }
    }
}
.circle-animation {
    & span{
        &.tp-circle-1{
            &::before {
                position: absolute;
                content: "";
                width: 22px;
                height: 22px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -12px;
                background-color: #FFADAD;
            }
        }
    }
}
.circle-animation {
    & span{
        &.tp-circle-2{
            &::after {
                position: absolute;
                content: "";
                width: 14px;
                height: 14px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                bottom: -25px;
                right: -7px;
                background-color: var(--tp-theme-1);
            }
        }
    }
}




.tp-country-img{
    & img{
        max-width: 100%;
    }
}