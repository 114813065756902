/*----------------------------------------*/
/*  15. industri CSS START
/*----------------------------------------*/

@use '../utils' as *;

.tpindustri{
    padding: 25px 35px;
    background-color: var(--tp-common-white);
    border-radius: 10px;
    display: inline-block;
    margin: 0px 10px;
    border-radius: 10px;
    box-shadow: 0px 13px 12px rgba(181, 184, 200, 0.03);
    margin-bottom: 20px;
    @media #{$xl} {
    padding: 20px 25px; ;
    }
    @media #{$lg} {
    padding: 18px 20px;
    margin: 0px 5px;
    margin-bottom: 15px;
    }
    @media #{$md,$xs} {
    padding: 20px 20px;
    }
}
.tp-industri-bg{
    background-color: var(--tp-grey-1);
    padding-top: 110px;
    padding-bottom: 120px;
    border-radius: 20px;
}
.tp-industri-space{
    padding-left: 90px;
    padding-right: 90px;
    text-align: center;
    @media #{$xl} {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$lg} {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media #{$md} {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media #{$xs} {
        padding-left: 0px;
        padding-right: 0px;
    }
}
.tp-industri-title{
    font-size: 18px;
    font-weight: 500;
    color: var(--tp-common-black);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    @media #{$xs} {
        font-size: 15px;
    }
    &::before{
        content: '';
        height: 20px;
        width: 20px;
        background-color: var(--tp-common-blue);
        display: inline-block;
        border-radius: 50%;
        margin-right: 12px;
        flex: 0 0 auto;
    }
}
.tp-ind-color-one{
    &::before{
        background-color: var(--tp-theme-primary);
    }
}
.tp-ind-color-two{
    &::before{
        background-color: var(--tp-common-dpink);
    }
}
.tp-ind-color-three{
    &::before{
        background-color: var(--tp-theme-1);
    }
}
.tp-ind-color-four{
    &::before{
        background-color: var(--tp-theme-1);
    }
}
.tp-ind-color-five{
    &::before{
        background-color: var(--tp-common-dpink);
    }
}
.tp-ind-color-six{
    &::before{
        background-color: var(--tp-common-blue);
    }
}
.tp-ind-color-seven{
    &::before{
        background-color: var(--tp-theme-primary);
    }
}

.bs-industri-1{
    position: absolute;
    left: 40px;
    top: 50px;
    animation: rotate2 5s linear infinite;
}