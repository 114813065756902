/*----------------------------------------*/
/*  11. feature CSS START
/*----------------------------------------*/

@use '../utils' as *;

.tp-feature-list {
    padding-bottom: 15px;

    &__icon-img {
        height: 60px;
        width: 60px;
        background-color: var(--tp-theme-1);
        text-align: center;
        line-height: 60px;
        border-radius: 50%;
        flex: 0 0 auto;

        @media #{$xs} {
            margin-bottom: 20px;
        }
    }

    &__content {
        & h4 {
            color: var(--tp-common-black);
            font-size: 24px;
            font-weight: 500;
        }

        & p {
            color: #8A90A2;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;

            @media #{$xs} {
                & br {
                    display: none;
                }

                font-size: 17px;
            }
        }
    }
    &:hover {
       & .fea-color-1{
            box-shadow: inset 0 0 0 40px rgba(30, 226, 207, 0.9);
            & svg {
                color: var(--tp-common-white);
                position: relative;
                z-index: 3;
            }
        }
        & .fea-color-2{
            box-shadow: inset 0 0 0 40px rgba(66, 62, 243, .9);
            & svg {
                color: var(--tp-common-white);
                position: relative;
                z-index: 3;
            }
        }
       & .fea-color-3{
            box-shadow: inset 0 0 0 40px rgba(253, 52, 110, .9);
            & svg {
                color: var(--tp-common-white);
                position: relative;
                z-index: 3;
            }
        }

    }
}

.tp-fea-img {
    @media #{$xs} {
        margin-bottom: 60px;
    }
}

.fea-color-1 {
    background-color: rgba(30, 226, 207, 0.15);
    transition: .5s;
    & svg{
        color: var(--tp-common-paste);
        transition: .5s;
    }
}

.fea-color-2 {
    background-color:rgba(66, 62, 243, .15);
    transition: .5s;
    & svg{
        color: var(--tp-theme-1);
        transition: .5s;
    }
}

.fea-color-3 {
    background-color: rgba(253, 52, 110, .15);
    transition: .5s;
    & svg{
        color:rgba(253, 52, 110, 1);
        transition: .5s;
    }
}

.tpchosebox {
    background-color: var(--tp-common-white);
    border-radius: 20px;
    box-shadow: 0px 26px 50px rgba(203, 206, 225, 0.24);
    padding: 50px 40px;

    @media #{$xl} {
        padding: 50px 34px;
    }

    &:hover {
        & .tpchosebox__icon a {
            transform: rotateY(-180deg);
        }
    }

    &__icon {
        & a {
            color: var(--tp-common-dpink);
            height: 60px;
            width: 60px;
            background-color: rgba(253, 52, 110, .15);
            display: inline-block;
            text-align: center;
            line-height: 64px;
            font-size: 26px;
            border-radius: 50%;
        }
    }

    &__content {
        h4 {
            color: var(--tp-common-black);
            font-size: 24px;
            font-weight: 500;
            padding-bottom: 15px;

            & a {
                background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
                display: inline;
                background-size: 0% 1px, 0 1px;
                background-position: 100% 100%, 0 100%;
                background-repeat: no-repeat;
                transition: background-size 0.4s linear;

                &:hover {
                    background-size: 0% 1px, 100% 1px;
                }
            }
        }

        & p {
            color: #8A90A2;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}

.fea-color-4 {
    & a {
        background-color: rgba(30, 226, 207, .15);
        color: var(--tp-common-paste);
    }
}

.fea-color-5 {
    & a {
        background-color: rgba(252, 220, 2, .15);
        color: var(--tp-theme-primary);
    }
}
.fea-wrapper-main{
    &:last-child{
        margin-bottom: 0;
    }
}
.tp-chose-bg {
    position: absolute;
    z-index: -1;
    left: 0;

    @media #{$xxl,$xl} {
        right: 50px;
    }

    @media #{$xl} {
        right: 70px;
    }

    @media #{$md} {
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }

    @media #{$xs} {
        position: static;
        margin-bottom: 70px;
    }

    & img {
        @media #{$xs} {
            max-width: 100%;
        }
    }
}

.ce-chose-shape {
    position: absolute;
    bottom: 130px;
    right: 100px;
    animation: moving 9s linear infinite;
    @media #{$xl,$lg} {
        bottom: 70px;
    }
}

.tp-chose-area {
    @media #{$xs} {
        padding-top: 120px;
    }
}

.tpchosebox-main {
    @media #{$xs} {
        margin-bottom: 70px;
    }
}

.tp-chose-space {
    transform: translateX(-75px);

    @media #{$xxl,$xl} {
        transform: translateX(-23px);
    }

    @media #{$xl,$lg} {
        transform: translateX(0);
    }

    @media #{$lg} {
        transform: translateX(-12px);
    }

    @media #{$md,$xs} {
        transform: translateX(0);
    }
}

.tp-feature-section-title-box {
    @media #{$lg} {
        .tp-title-sm {
            font-size: 29px;
        }
    }

    @media #{$md} {
        margin-top: 60px;
    }

    @media #{$xl} {
        padding-left: 40px;
    }

    & p {
        @media #{$xs} {
            font-size: 15px;
        }
    }
}

.tp-fea-img {
    @media #{$md} {
        margin-bottom: 70px;
    }

    & img {
        max-width: 100%;
    }
}

.tp-feature-list {
    @media #{$xs} {
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
}

.ac-feature-title {
    font-size: 50px;
    color: var(--tp-common-black);
    font-weight: 700;
    line-height: 1.2;

    @media #{$md} {
        font-size: 42px;
        margin-bottom: 30px;
    }

    @media #{$xs} {
        font-size: 35px;
        margin-bottom: 30px;
    }
}

.ac-feature-right {
    padding-left: 55px;

    @media #{$xl} {
        padding-left: 35px;
    }

    @media #{$lg,$md,$xs} {
        padding-left: 0px;
    }
}

.acfeature {
    text-align: center;
    margin-top: -17px;

    &__item {
        & p {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            padding: 0 2px;
        }
    }
}

.ac-feature-sm-title {
    font-size: 24px;
    color: var(--tp-common-black);
    font-weight: 700;
    transition: .3s;

    &:hover {
        color: var(--tp-common-dpink);
    }
}

.ac-circle {
    height: 30px;
    width: 30px;
    background-color: var(--tp-common-white);
    box-shadow: 0px 1px 40px #D4DAE8;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-bottom: 20px;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 15px;
        width: 15px;
        background-color: var(--tp-common-dpink);
        box-shadow: 0px 1px 40px #D4DAE8;
        border-radius: 50%;
        display: inline-block;
        content: '';
        transform: translate(-50%, -50%);
    }
}

.ac-feature-space {
    padding-bottom: 80px;
}

.ac-feature-border-top {
    border-top: 1px solid rgba(41, 41, 48, .08);

    @media #{$md,$xs} {
        border-top: transparent;
    }
}

.ac-cirle-color-2 {
    transition: .3s;

    &:hover {
        color: var(--tp-common-paste);
    }

    &::after {
        background-color: var(--tp-common-paste);
    }
}

.ac-cirle-color-3 {
    transition: .3s;

    &:hover {
        color: var(--tp-theme-primary);
    }

    &::after {
        background-color: var(--tp-theme-primary);
    }
}

.ac-cirle-color-4 {
    transition: .3s;

    &:hover {
        color: var(--tp-theme-1);
    }

    &::after {
        background-color: var(--tp-theme-1);
    }
}