//
//pagination.scss
//

.pagination {
  .page-item {
    .page-link {
      border-radius: 40px;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 4px;

      color: #c03c43;

      &:hover,
      &:focus {
        color: #2d2d2d;
      }
    }
    &.active {
      .page-link {
        background-color: #c03c43;
        border-color: #c03c43;
        color: #fff;

        &:hover,
        &:focus {
          color: #fff;
        }
      }
    }
  }
}
