/*----------------------------------------*/
/*  21. price CSS START
/*----------------------------------------*/


@use '../utils' as *;

.cpprice {
    border: 1px solid #F0F1F6;
    border-radius: 20px;
    padding: 47px 47px 55px 47px;

    &__meta {
        margin-bottom: 30px;

        & span {
            font-size: 20px;
            color: var(--tp-common-black);
            font-weight: 400;

            & b {
                font-size: 50px;
                font-weight: 500;
                color: var(--tp-common-black);
            }
        }
    }

    &__list {
        & ul {
            & li {
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                color: #8A90A2;
                margin-bottom: 17px;
            }
        }
    }

    &__button {
        padding-top: 80px;
    }
}

.cp-price-title {
    font-size: 35px;
    font-weight: 400;
    color: var(--tp-common-black);
    border-bottom: 1px solid #F0F1F6;
    padding-bottom: 40px;
    margin-bottom: 60px;
}

.cp-price-sm-title {
    font-size: 16px;
    color: var(--tp-common-black);
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 40px;
}

.cpprice.active {
    background-color: var(--tp-common-blue);

    & .cpprice__list {
        & ul {
            & li {
                color: var(--tp-common-black);
            }
        }
    }

    & .cpprice__button {
        & .tp-btn-yellow {
            background-color: var(--tp-common-white);
        }
    }
}

.jobitem {
    border: 1px solid #F6F6F6;
    padding: 50px 38px;
    border-radius: 20px;
    transition: .3s;
    &__brand{
        margin-bottom: 45px;
    }
    &__details {
        & p {
            font-weight: 400;
            font-size: 16px;
            text-transform: capitalize;
            color: #8A90A2;
            padding-bottom: 10px;
            & i{
                padding-right: 8px;
            }
            &:last-child{
                padding-bottom: 36px;
            }
        }
    }
    &:hover{
        box-shadow: 0px 16px 11px rgba(224, 224, 224, 0.25);
    }
}

.job-title-sm {
    font-size: 20px;
    color: var(--tp-common-black);
    line-height: 32px;
    font-weight: 500;
    padding-bottom: 25px;
    & a {
        background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
        display: inline;
        background-size: 0% 1px, 0 1px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 0.4s linear;
        &:hover{
            background-size: 0% 1px, 100% 1px;
        }
    }
}
