@use '../utils' as *;

/* pulse effect animation */

@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}

@keyframes tfLeftToRight {
    49% {
        transform: translateX(30%);
    }
    50% {
        opacity: 0;
        transform: translateX(-30%);
    }
    51% {
        opacity: 1;
    }
}


@keyframes rotate2 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@keyframes moving {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(-50px);
    }
    50% {
      transform: translatey(-40px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes leftright {
    0% {
  
      transform: translatex(0);
    }
    50%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
    }
  
  }

@keyframes movinglight {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(-70px);
    }
    50% {
      transform: translatey(-70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes movinglight2 {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translateX(70px);
    }
    50% {
      transform: translatey(70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }
@keyframes movingtop {
    0% {
  
      transform: translatey(0px);
    }
    20%{
        transform: translatey(70px);
    }
    50% {
      transform: translatey(-70px);
    }
  
    100% {
      transform: translatey(0px);
    }
  }

@keyframes circle-animation {
    0%{
     transform:rotate(0deg);
    }
    100%{
         transform:rotate(360deg);
    }
}

@keyframes circle-animation2 {
    0%{
        transform:scale(-1, 1) rotate(360deg);
    }
    100%{
        transform: scale(-1, 1) rotate(0deg);     
    }
}
@keyframes zoom {
    0%{
        transform:scale(.5);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale( .5);     
    }
}
@keyframes zoom2 {
    0%{
        transform:scale(.9);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale(.9);     
    }
}

@keyframes wobble-vertical{
  10% {
      transform: translateY(-10px);
  }
  50% {
      transform: skew(15deg);
  }
  80% {
      transform: rotate(10deg);
  }
  100% {
      transform: translate(0);
  }
}



@include keyframes(section-highlight){
	0%  {
      width: 0%;
    }

    100%{
      width: 100%; 
    }
}


@keyframes widthcalc {
  0% {
      width: 20%;
  }
  50% {
      width: 50%;
  }
  75% {
    width: 90%;
  }
  90% {
      opacity: 75%;
  }
  90% {
      opacity: 100%;
  }
}


/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-90px);
    transform: translateX(-90px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-90px);
    transform: translateX(-90px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-90px);
    transform: translateX(-90px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */


// animation  icon-bounce
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    @include transform(translateY(0));
  }
  40% {
    @include transform(translateY(-10px));
  }
  60% {
    @include transform(translateY(-5px));
  }
}