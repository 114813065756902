@use '../utils' as *;

/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: var(--tp-grey-1);
}
.white-bg {
	background: var(--tp-common-white);
}
.black-bg {
	background: var(--tp-common-black);
}
.theme-bg {
	background: var(--tp-theme-2);
}
.sky-bg {
	background: var(--tp-common-blue);
}
.yellow-bg {
	background: var(--tp-theme-primary);
	border-radius: 20px;
}
.bg-radius-none{
    border-radius: inherit;
}

